//
//** Demo Config
//


//== Layout Shared Settings
$m-aside-left-width: 300px;
$m-aside-left-width-minimize: 70px;

$m-aside-left-offcanvas-width: 255;
