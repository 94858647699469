//
//** Override The Base Framework Config For This Demo
//

//== Example(reduce font size from 14px to 13px globally for desktop mode only)

//== The body element font settings
/**
$m--root-font: (
    size: (
        desktop: 13px,
        tablet: 13px,
        mobile: 13px
    ),
    weight: 300,
    color: (
        light: #333333,
        dark: #efefef
    )
);
*/

//== UI State Color
$m--state-colors: (
  // metronic states
        brand: (
          // @edited from MJH
                base: $leu-blue,
                inverse: #ffffff
        ),
        metal: (
                base: #c4c5d6,
                inverse: #111111
        ),
        light: (
                base: #ffffff,
                inverse: #282a3c
        ),
        accent: (
                base: #00c5dc,
                inverse: #ffffff
        ),
        focus: (
                base: $leu-blue,
                inverse: #ffffff
        ),

  // bootstrap states
  // @edited from MJH
        primary: (
                base: $leu-blue,
                inverse: #ffffff
        ),
        success: (
                base: #34bfa3,
                inverse: #ffffff
        ),
        info: (
                base: #36a3f7,
                inverse: #ffffff
        ),
        warning: (
                base: #ffb822,
                inverse: #111111
        ),
        danger: (
                base: #f4516c,
                inverse: #ffffff
        )
);

$custom-file-text: (
        placeholder: (
                en: "Choose file...",
                de: "Datei auswählen..."
        ),
        button-label: (
                en: "Browse",
                de: "Durchsuchen"
        )
);